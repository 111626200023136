/*
Theme Name: Clean Tag Theme
Theme URI: http://creativepickle.com/
Description: Custom Theme for Clean Tag
Author: CreativePickle
Version: 1.0
Tags: 
*/

@import url('reset.css');
@import (less) "mixins.less";

/****LAYOUT****/
html, body, #container {height: 100%; width:100%;}

body > #container {height: auto; min-height: 100%;}

#container{
    position:relative;
    }

body{
    background:#fff url(images/bg.png) repeat 0 0;
    &.page-template-homepage.php{
        
    }
}



#main {
    width:@mainWidth;
	padding:0;
	margin:0px auto;
    }
    
span.book{
    font-weight:400!important;
    }
    
#content{
    width:@mainWidth;
	padding:30px 0 30px 0;
	.bodyfont();
	&.no-pad{
	    padding:0;
	    }
	  
	}

.gray-bg{
	  	background: #f2f2f2;
	  	}
	
#content-wrap{
   
    }

#main-content{
	&.wide{
		padding-right:0px!important;
	padding-left:0!important;
	}
	
    line-height:1.5;
    .central.top-content{
        padding-right:10%!important;
        padding-left:10%!important;
        }
     ul{
            margin:10px 0 20px 0;
            padding:0;
            li{
                padding-left:20px;
                margin-left:0!important;
                position:relative;
                line-height:1.2!important;
                &:before{
                    content:'';
                    
                    width:6px;
                    height:6px;
                    background:@green;
                    position:absolute;
                    top:12px;
                    left:0;
                    }
                }
            }
    ul.inline{
        li{
            display:inline-block;
            width:30%;
            }
        }
    img, .image-scaler{
      
        }
    h2{
        .os-condensed(32px);
        color:@greenDark;
        .uppercase();
        margin:15px 0 15px 0;
        letter-spacing:.03em;
        }
    h3{
        .oswald(26px);
        .uppercase();
        font-weight:300;
        color:@grayLight;
        }
    h6{
        .oswald(24px);
        color:@green;
        .uppercase();
        font-weight:300;
        margin-bottom:15px;
        }
    a{
    	color:@green;
    	&:hover{
    		color:@grayLight;
    		}
    	}
    }
	
#main-content #sidebar{
	padding:0px 0 0 40px!important;
	.gform_wrapper{
		margin:10px 0 0 0!important;
		position: relative!important;
		label{display: none!important;}
		ul.gform_fields{
			>li{
				padding:0!important;
				position: relative;
				margin:0!important;
				input[type=text]{
				padding-right: 35px!important;
				}
				}
			}
		}
		.gform_footer{
			margin:0!important;
			padding:0!important;
			.inline-block()!important;
			width:auto!important;
			position: absolute!important;
			top:16px;
			right:5px;
			
			button.submit{
				.inline-block();
				color:@green;
				transition: all linear 200ms;
				.scale(1);
				background:none;
				border:none!important;
				font-size:20px!important;
				opacity:0.9;
				&:hover{
					.scale(1.1);
					cursor: pointer;
					opacity:1;
					}
				}
			}
		.validation_error{
			font-size:16px!important;
			color:@green!important;
			}
		.gform_confirmation_message{
			color:@green!important;
			.uppercase();
			padding-top:15px!important;
			}
	}

.blog-wrap{
		padding-right:40px!important;
		border-right:1px solid @grayLight;
		}

ul.widget-list{
	padding:0!important;
		margin:0!important;
	>li{
		background:none!important;
		padding:0!important;
		margin:0!important;
		&:before{display:none!important;}
		a{
			display: block;
			border-bottom:1px solid @grayLight;
			padding:10px 0 10px 0;
			.transition();
			.oswald(16px);
			font-weight: 300;
			.uppercase();
			color:#3e3e3d;
			&:hover{
				background:@green;
				color:#fff!important;
				padding:10px;
				}
			}
		}
	}
	
ul.subpage-menu{
    width:100%;
    padding:0;
    margin:0 0 40px 0;
    >li{
        display:block;
        margin:0;
        padding:0;
        >a{
            display:block;
            padding:10px;
            border-top: 2px solid @defaultBG;
            .transition(all linear 130ms);
            font-size:20px;
            .text-shadow();
            &:hover{
                background:#f1f1f1;
                padding-left:15px;
                }
            }
            &.current_page_item>a, &.current_page_ancestor>a{
                background:#f1f1f1;
                padding-left:15px;
                }
            &:first-child>a{border-top:none;}
            >ul.children{display:none;}
            &.current_page_item>ul.children, &.current_page_ancestor>ul.children{
                padding:0;
                margin:0;
                display:block;
                >li{
                    >a{
                        .italic();
                        padding:10px;
                        border-top: 2px solid #e6e6e6;
                        .transition(all linear 130ms);
                        font-size:16px;
                        .text-shadow();
                        display:block;
                        &:hover{color:@accent;}
                        }
                         &:first-child>a{border-top:none;}
                         &.current_page_item>a{color:@accent;}
                    }
                }
        }
    }
    
#section-wrap{
    margin-top:30px;
    >div.stripe-section{
        &:nth-child(odd){
            background:#f2f2f2;
            }
        padding:40px 0 40px 0;
        ul.inline{
            li{
                .inline-block();
                width:48%;
                margin-right:1%;
                }
            }
        }
    }
    
.subsection-wrap{
	margin-top:50px;
	.subsection{
			text-align: right;
			position: relative;
			margin-bottom:100px;
			overflow: visible;
		i{
			position: absolute;
			.inline-block();
			color: #3e3e3d!important;
			z-index: 1;
			bottom:-30px;
			font-size: 24px;
			left:50%;
			transform:translateX(-50%);
			}
		.icon{
			.inline-block();
			height:100px;
			width:100px;
			.rounded-corners(50%);
			background:@green;
			position: absolute;
			top:50%;
			left:0;
			margin-top:-50px;
			background-size:50% auto!important;
			}
		.text{
			.inline-block();
			width:~"calc(100% - 50px)";
			background:#fff;
			padding:30px 30px 30px 80px;
			border:2px solid @green;
			text-align: left;
			
			}
		}
	}
    
.stripe-section.testimony{
    .central{
        padding:0 120px 0 120px!important;
        position:realtive;
        span.quote{
            .oswald(160px);
            color:@green;
            position:absolute;
            font-weight:300;
            top:0;
            line-height:1;
            &.open{
                left:0;
                }
            &.close{
                right:0;
                }
            }
            cite{
                .oswald(18px);
                color:@greenDark;
                font-style:normal;
                font-weight:300;
                .uppercase();
                }
        }
    }

ul#process-icons{
    margin:40px 0 0 0!important;
    padding:0!important;
    li{
        margin:0 30px 0 30px!important;
        padding:0!important;
        .centered();
        width:140px;
        .inline-block();
       position:relative;
        &:before{display:none!important;}
        &:last-child{
            i{display:none;}
            }
        i{
            color:#d7d5d5;
            position:absolute;
            top:50px;
            right:-40px;
            }
        img{
            margin-bottom:10px;
            max-width:120px;
            .inline-block();
            }
        h6{font-size:18px;}
        }
    }

#services-page{
    button{
        color:@green!important;
        &:hover{
            color:@greenDark!important;
            }
        }
    }

#services-gallery{
    margin:40px 0 40px 0!important;
    padding:0 40px 0 40px!important;
    .centered();
    >div{
        margin:0!important;
        padding:10px 20px 0 20px!important;
        .img-wrap{
            width:100%;
            max-width:240px!important;
            .transition();
                &:hover{
                    .scale(1.05);
                    }
            img{
                max-width:100%!important;
                height:auto;
                
            }
            }
    }
    }
    
.img-wrap, .no-photo{
 position: relative;
 margin-bottom:15px;
 width:auto;
 .inline-block();
 border:3px solid #e5e4e4;
&:before, &:after{
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 5px;
  width: 50%;
  top: 80%;
  max-width:300px;
  background: #777;
  box-shadow: 0 15px 10px #777;
  transform: rotate(-3deg);
}
&:after{
  transform: rotate(3deg);
  right: 5px;
  left: auto;
}
}

/*********ABOUT PAGE********/
#team{
    background:@grayDark;
    padding:10px 0 10px 0;
    .top-bar{
        background:#fff;
        width:100%;
        height:90px;
        position:absolute;
        top:0;
        left:0;
        }
    }

#team-wrap{
    padding:0 40px 0 40px;
    button{
        top:30%!important;
        }
    }

.team-slide{
    outline:none;
    .centered();
    .os-condensed(20px);
    color:#fff;
    padding:0 30px 0 30px;
    position:relative;
    z-index:10;
    img{
        .inline-block()!important;
        max-width:80%;
        height:auto;
        .rounded-corners(50%);
        
        border:4px solid #fff;
        margin-bottom:15px;
        .no-box-shadow();
        }
    
    }

#about-page{
    .os-condensed(26px);
    color:@content;
    padding-top:0;
    .right-align{
        text-align:right;
        
        padding-left:10%;
        padding-right:30px;
        }
    .left-align{
        text-align:left;
        border-left:2px solid @grayLight;
        padding-right:10%;
        padding:0 0 20px 30px;
        font-size:22px;
      ul li{margin-bottom:10px;}
        }
    }
    
#action{
    padding:120px 0 120px 0;
    }

#footer {
	position: relative;
	width:@mainWidth;
	background:@green;
    padding:15px 0 15px 0;
    overflow:visible;
	clear:both;} 
	
.footer-content{
	text-align:center;
	padding:0;
	color:#fff;
	.text-shadow();
	#copyright{
	    .os-condensed(15px);
	    padding-top:7px;
	    a{
	        color:#fff;
	        &:hover{
	            color:@greenDark;
	            }
	        }
	    }
	 a.footer-button{
	     color:#fff;
	     display:block;
	     .oswald(25px);
	     .uppercase();
	     font-weight:300;
	     position:relative;
	     overflow:visible;text-align:right;
	     padding-right:180px;
	     .transition(all linear 300ms);
	     span{position:relative;z-index:5;}
	     strong{font-weight:400;}
	     img.toolbox{
	         position:absolute;
	         width:180px;
	         height:auto;
	         right:0;
	         bottom:-10px;
	         .transition(all linear 300ms);
	         z-index:0;
	         }
	         &:hover{
	             padding-right:190px;
	             img.toolbox{
	                 width:190px;
	                 }
	             }
	     }
	}
	
.section{
    width:100%;
    position:relative;
    min-height:200px;
    padding:60px 0 60px 0;
    }
    
h5.section-head, h1.pagetitle{
    .oswald(50px);
    .uppercase();
    font-weight:300;
    margin-bottom:25px;
    &.green{
        color:@green;
        }
    strong{font-weight:400;}
    }
    
.vert-center-wrap{
   position:relative
    .vert-center{
       position: relative;
      top: 50%;
      transform: translateY(-50%);
    
        }
    }
	
/**********PAGE STYLES**********/

.callout{
    display:block;
    .uppercase();
    position:relative;
    padding:0 60px 0 60px;
    .centered();
    .quote{
        display:block;
        width:50px;
        height:50px;
        position:absolute;
        &.open{
            top:0;
            left:0;
            background:url(images/open-quote-callout.png) no-repeat 0 0;
            }
        &.close{
            bottom:0;
            right:0;
            background:url(images/close-quote-callout.png) no-repeat 0 0;
            }    
        }
    &.left{
        width:100%;
        max-width:340px;
        float:left;
        margin:0 20px 15px 0;
        }
    &.right{
        width:100%;
        max-width:340px;
        float:right;
        margin:0 0 15px 20px;
        }
    &.center{
        width:100%;
        border-top:1px solid #d9d8d3;
        border-bottom:1px solid #d9d8d3;
        padding:15px 20px 15px 20px;
        margin:10px 0 10px 0;
        .quote{
            display:none!important;
            }
        }
     }

.image-scaler{
    .scaler();
    min-width:100px;
    height:auto;
    }

/***********HEADER and NAVIGATION*********/
@menuLink: #666;
@menuLinkHover: #333;

#main-header{
    min-height:100px;
    position:absolute;
    width:100%;
    top:0;
    left:0;
    padding:20px 0 20px 0;
    z-index:500;
    background:transparent;
    .central{
        position:relative;
        z-index:510;
        }
    img.logo{
       max-width:40%; 
        }
     .slider{
        height:100%;
        width:100%;
        background:#3e3e3d;
        position:absolute;
        top:calc(~"-100% + 5px");
        left:0;
        .transition(all ease-out 350ms);
        z-index:505;
        border-bottom:5px solid @green;
        
        }
    &.subpage{
        position:relative!important;
        .slider{
            height:55%;
            top:0;
            border-top:5px solid @green;
            border-bottom:none;
            }
        }
}

#nav{
    
}

#menu-main-navigation-1{
    margin-top:10px;
    text-align:right;
    list-style:none;
    padding:0;
    >li{
        .inline-block();
        margin-right:4px;
        position:relative;
        &.current_page_item>a, &.current-page-ancestor>a{border:1px solid #fff!important;}
     
        
        >a{
            .oswald(24px);
            color:#fff;
            font-weight:300;
            letter-spacing:.02em;
            display:block;
            padding:10px;
            .transition();
            border:1px solid transparent;
            .text-shadow-custom(1px, 1px, 2px, rgba(0, 0, 0, 1));
            }
        a:hover{
            color:#fff;
            border:1px solid #fff;
            }
        }
    }
 
ul#menu-social-navigation{
    .inline-block();
    margin:0;
    padding:0;
    li{
        .inline-block();
        color:@green;
        font-size:45px;
        padding:0;
        margin:0;
        position:relative;
        margin:0 10px 0 10px;
        .transition();
        &:hover{color:@greenDark;}
        a{
            display:block;
            text-align:left;
            text-indent:-9999px;
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            }
        }
    }   	
/***********HEADER STYLES*******/

h1{
    color:#333;
    font-size:36px;
    padding:0 0 12px 0;
    }
    
h2{
    font-size:30px;
    color:#333;
    padding:0 0 12px 0;
    }
    
h3{
    font-size:24px;
    color:#333;
    padding:0 0 8px 0;
    }
    
h4{
    font-size:18px;
    padding:0 0 8px 0;
    }
    
/***********BUTTONS***********/

a.button{
    line-height:18px!important;
    display:inline-block;
    font-size:18px;
    span.text{
        line-height:18px!important;
        letter-spacing:.04em;
        .inline-block();
        vertical-align:top;
    .oswald(18px);
    .text-shadow();
    background:@green;
    padding:10px 15px 10px 15px;
    color:#fff!important;    
    .centered();
    .uppercase();
    .transition();
    }
    span.arrow{
        line-height:18px!important;
        .inline-block();
        background:@greenDark;
        vertical-align:top;
        font-size:18px;
        .text-shadow();
        .centered();
        padding:10px;
        margin-left:3px;
        color:#fff;
        .transition();
        height:100%;
        height:38px;
        }
    &:hover{
        span.text{background:@greenDark;}
        span.arrow{background:@green;}
        }
    }


/*********HOMEPAGE**********/


#banner{
    border-bottom:1px solid #fff;
    position:relative;
    #banner-slide-wrap{
        position:relative;
        outline:none;
        height:100vh;
        
        .banner-slide-item{
            height:100vh;
      
            .scaler();
            }
        }
    
    &.subpage{
        background:@accent;
        padding:30px 0 30px 0;
        margin-bottom:45px;
        border-bottom:1px solid #fff;
        h1{
        color:#fff;
        .uppercase();
        .bold();
        }
        }
    
    }
    
button.slick-next,button.slick-prev{
        background:none;
        color:#fff;
        height:24px;
        outline:none;
        padding:0;
        font-size:22px;
        position:absolute;
        top:50%;
        margin-top:-12px;
        .transition();
        border:none;
        z-index:500;
        &:hover{
            color:@green;
            cursor:pointer;
            }
        }
    button.slick-next{
        right:20px;
        text-align:right;
        &:hover{
            color:@green;
            right:16px;
            }
        }
    button.slick-prev{
        left:20px;
        text-align:left;
        &:hover{
            color:@green;
            left:16px;
            }
        }
    
#testimonials{
    background:@grayDark;
    padding:30px 0 30px 0;
    .centered();
    .testimonial-slide{
        outline:none;
        padding:30px 70px 10px 70px;
        .os-condensed(24px);
        position:relative;
        color:#fff;
        cite{
            .inline-block();
            .oswald(20px);
            .uppercase();
            font-weight:300;
            font-style:normal;
            color:@green;
            }
        span.quote{
            .oswald(160px);
            color:@green;
            position:absolute;
            font-weight:300;
            top:0;
            line-height:1;
            &.open{
                left:0;
                }
            &.close{
                right:0;
                }
            }
        }
    }
    
ul.slick-dots{
   .inline-block();
   vertical-align:middle;
    .centered();
   margin-top:40px;
   padding:10px!important;
   border:2px solid #fff;
    z-index:50;
    line-height:1!important;
    li{
         line-height:1!important;
        .inline-block();
        vertical-align:middle;
        padding:0;
        margin:0 6px 0 6px;
        padding:0!important;
        height:10px!important;
        position:relative;
        top:-3px;
        button{
            
            line-height:1!important;
            text-align:left;
            background:transparent;
            border:2px solid #fff;
            outline:none;
            display:block!important;
            width:10px!important;
            height:10px!important;
            .transition(all linear 150ms);
            text-align:left;
            text-indent:-9999px;
            padding:0!important;
            &:hover{
                cursor:pointer;
                background:#fff;
                }
            }
        &.slick-active{
            button{
                background:#fff!important;
                }
            }
        }
    }
    
.banner-content{
    width:100%;
     position: absolute;
              top: 50%;
              transform: translateY(-50%);
        background:rgba(0,0,0,0.8);
        padding:20px;
       z-index: 10;
        .bold();
        color:@accent;
   
    h5{
        .uppercase();
        color:@green;
        font-weight:300;
        line-height:1!important;
        .oswald(80px);
		span.subtitle{
			display: block;
			font-size: 24px;
			letter-spacing: .14em;
			padding:15px 0 15px 0;
			color:#fff;
			}
        }
    
    }
    
#who{
    overflow:visible;
    background:#fff;
    padding:100px 0 100px 0;
    .bg{
        max-width:340px;
        height:560px;
        position:absolute;
       left:0;
       width:20%;
        bottom:0;
        background:url(images/tools.png) no-repeat top right;
        }
    .central{
        padding:0 15% 0 15%!important;
        
        }
    }
    
#services{
    padding:100px 0 100px 0;
    background:url(images/wood.jpg) repeat 0 0;
    a.button{
        span{
        .box-shadow-custom(0,0,15px,rgba(0,0,0,0.4));
        }
        }
    ul#service-icons{
        margin-bottom:60px;
        li{
            .inline-block();
            margin:0px 50px 0 50px!important;;
            }
        }
    }
    
#connect{
    padding:100px 0 100px 0;
    .facebook{
        height:340px;
        border-right:1px solid @grayLight;
        }
       .center-title{
       	border-right:1px solid @grayLight;
       	padding:60px 40px 60px 40px;
       	}
    .connect-details{
        position: relative;
     
        div{
             
             
            }
        }
    }
    
ul.footer-menu{
    margin:25px 0 25px 0!important;
    padding:0!important;
    li{
        .os-condensed(22px);
        font-weight:300;
        color:@content;
        margin-bottom:8px;
        padding-left:0!important;
        &:before{
            display:none;
            }
        i{color:@green;margin-right:5px;}
        a{
            color:@content;
            &:hover{
                color:@green;
                }
            }
        }
    }

/**********ITEMS and WIDGETS**************/

.item{
    width:100%;
    margin-bottom:30px;
    padding-bottom:30px;
    border-bottom:1px solid @content;
    
}

.blog-item{
	margin-bottom:30px;
	padding-bottom: 30px;
	border-bottom: 1px solid @grayLight;
	a.readmore{display: none;}

		div.title{
			
			text-align:left;
			h5{
				display: inline-block;
				color:@green;
				.oswald(32px);
				.uppercase();
				font-weight:300;
				border-bottom:2px solid @green;
				padding-bottom:10px;
				margin-bottom: 10px;
				}
			h6{
				display: block;
				color:@greenDark!important;
				}
	
		}
	}
	


.widget{
    margin-bottom:50px;
    padding-bottom:50px;
    h5.widget-title{
        .oswald(24px);
        color:@green;
        background:#3e3e3d;
        .uppercase();
        padding:5px 10px 5px 10px;
        maergin-bottom:15px;
    }
}
 

/********ALIGNMENT********/

/* Alignment */
.alignleft {
    display: inline;
	float: left;
	margin-right: 1.625em;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.625em;
}
.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft,
img.alignright,
img.aligncenter {
    margin-bottom: 1.625em;
}	

.clear{
    clear:both;
    }  

.wp-caption-text{
    font-size:12px;
    .italic();
    }
    
figure{
    -webkit-margin-before: 0;
-webkit-margin-after: 0;
-webkit-margin-start: 0;
-webkit-margin-end: 0;
    }
    
.fixed-bg{
    background-attachment:fixed!important;
    }
    
.scaler{
    position:relative;
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}

.centered{
    text-align:center;
    }
    
/**********PROJECT PAGES*********/

.gallery-wrap{
	margin-top:30px;
	}
.gallery-item{
	padding:10px!important;
	line-height: 1!important;
	.gallery-image{
		border:1px solid #fff;
		.scaler();
		height:440px;
		position: relative;
		.fader{
			position: absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background:rgba(0,0,0,0.8);
			color:@green;
			padding:10px!important;
			i{
				.inline-block();
				font-size:30px;
				position: absolute;
				left:50%;
				top:50%;
				transform:translateX(-50%) translateY(-50%);
				
				}
			.caption{
				width:96%;
				.uppercase();
				font-size:20px;
				text-align: right;
				left:2%;
				bottom:0;
				padding:20px 0 20px 0;
				
				position: absolute;
				>span{
					display: block;
					opacity:0;
					transform: translateY(10px);
					}
				div.bg{
					width:0;
					height:1px;
					background:@green;
					position: absolute;
					top:0;
					left:0;
					}
				}
			}
		}
	&.full-width{
		.gallery-image{
			height:600px;
			}
		}
	}
a.block{
	width:100%;
	height:100%;
	position: absolute;
	top:0;
	left:0;
	display: block;
	z-index:10;
	}
	
.credits-box{
	width:100%;
	max-width: 300px;
	float:right;
	margin:0 0 40px 30px;
	font-weight: 400;
	ul li{
		margin-bottom: 12px!important;
		}
	}
	
.project-item{
	padding:20px!important;
	.project-image{
		position: relative;
		border:4px solid #f2f2f2;
		.scaler();
		height:450px;
		&:before, &:after{
			  z-index: -1;
			  position: absolute;
			  content: "";
			  bottom: 15px;
			  left: 5px;
			  width: 50%;
			  top: 80%;
			  max-width:300px;
			  background: #777;
			  box-shadow: 0 15px 10px #777;
			  transform: rotate(-3deg);
			}
		&:after{
			  transform: rotate(3deg);
			  right: 5px;
			  left: auto;
			}
			.fader{
			position: absolute;
			top:0;
			left:0;
			width:100%;
			.transition(all linear 200ms);
			height:100%;
			background:rgba(0,0,0,0.3);
			color:@green;
			padding:10px!important;
			
			.title{
				width:94%;
				.uppercase();
				font-size:20px;
				text-align: left;
				left:3%;
				top:50%;
				transform:translateY(-50%);
				padding:20px 20px 20px 0;
				position: absolute;
				font-weight:300;
				letter-spacing: .08em;
				i{
					.inline-block();
					position: absolute;
					right:0;
					font-size: 40px;
					top:50%;
					transform: translateY(-50%);
					.transition(all linear 200ms);
					}
				>span{
					display:inline- block;
					opacity:1;
					transform: translateY(10px);
					.oswald(40px);
					color:#fff;
					.uppercase();
					.transition();
					}
				div.bg{
					width:100%;
					height:3px;
					background:@green;
					position: absolute;
					bottom:0;
					left:0;
					z-index:10;
					&.cover{
						background:#fff;
						z-index:11;
						width:0;
						}
					}
				}
			}
		&:hover{
			.fader{
					background:rgba(0,0,0,0.4);
				}
			.title{
				>span{color:@green;}
				i{color:#fff;}
				}
			}
		}
	
	}
	
.navigation{
	>div{
		padding:40px 10px 40px 10px;
		&.right{
			float:right!important;
			}
		>a{
			display: block;
			border:2px solid @green;
			.uppercase();
			.centered();
			padding:15px;
			i.fa-long-arrow-right{margin-left:12px;}
			i.fa-long-arrow-left{margin-right:12px;}
			.transition();
			&:hover{
				background:@green;
				color:#fff!important;
				}
			}
		}
	}
	
br.clear{display: block; /* makes it have a width */
            content: ""; /* clears default height */
            margin-top: 0; /* change this to whatever height you want it */}
            